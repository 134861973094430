//TEST ENVIRONMENT
// export const BASE_URL = window.location.origin !== 'https://mutiparty-uat.iamdave.ai' ? "https://test.iamdave.ai/" : "https://msil-multipartychat-dashboardv2-uat.gryd.in/";
export const BASE_URL = "https://msil-multipartychat-dashboardv2-uat.gryd.in/";

// DMS API
// export const dMSAPI = "http://dmssales.co.in:8071/arenaservice.svc/VehicleDetails";
export const dMSAPI = "http://dmssales.co.in:8071/arenaservice.svc/VehicleDetails";

// PASS THROUGH API
export const PASS_THROUGH_API = "pass_through_api/dave";

//LOGIN
export const LOGIN_API = "login";

//USER ROLE
export const USER_ROLE_API = "object/agent/";

//SERVICE ADVISOR
// export const GET_SERVICE_ADVISORS = "pivot/agent/";
export const GET_SERVICE_ADVISORS = "options/agent/";

// GET SERVICE CENTER
export const GET_SERVICE_CENTER = 'agent_full_name?service_center_id=';

// GET ROLE
export const GET_ROLE = '&agent_role=';

// GET JOB STATUS
export const GET_JOB_STATUS = 'pivot/service_job/status';

//SERVICE JOBS
export const GET_SERVICE_JOBS = "objects/service_job?";

// UPDATE ADVISORS
export const UPDATE_TICKET = "object/service_job/"

//CUSTOMER
export const MAIN_API = "conversation/deployment_services_multiparty/";

// SOCKET 
// export const SOCKET_URL = window.location.origin !== 'https://mutiparty-uat.iamdave.ai' ? "https://multiparty-orchestrator.iamdave.ai/" : "https://msil-multiparty-orchestrator-uat.gryd.in/";
export const SOCKET_URL = "https://msil-multiparty-orchestrator-uat.gryd.in/";

// KEYWORDS SEARCH
export const KEYWORDS_SEARCH = "&search_term=~";

// SEARCH SERVICE CENTER
export const SERVICE_CENTER = "&service_center_id=";

// PAGE SIZE

// PREVIOUS MESSAGE
export const PREVIOUS_MESSAGE = "objects/dave_message?_sort_by=sent_timestamp&_sort_reverse=true";

// NEW STATUS
export const NEW_STATUS = "pivot/dave_message/room_id?sender_id=";
