import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import * as API from "./API_Path";

export const loginApi = createAsyncThunk("loginAPI", async (para,{rejectWithValue}) => {
    try {
        const response = await axios.post(API.BASE_URL+API.LOGIN_API,para,{});
        const result = response.data;
        console.log(response);
        console.log(result);
        return(response)
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.response);
    }
});

export const login = createSlice({
    name : "login",
    initialState : {
        userData : [],
        loading : null,
        error : "",
        api_key : "",
        enterprise_id : "",
        role : "",
        user_id : "",
        statusCode : ""
    },
    reducers : {
        apiKey: (state,action) => {
            state.api_key = action.payload;
        },
        enterpriseId: (state,action) => {
            state.enterprise_id = action.payload;
        },
        userRole: (state,action) => {
            state.role = action.payload;
        },userId: (state,action) => {
            state.user_id = action.payload;
        },
    },
    extraReducers : {
        [loginApi.pending] : (state) => {
            state.loading = true;
            state.error = "";
        },
        [loginApi.fulfilled] : (state, action) => {
            state.loading = false;
            state.userData = action.payload.data;
            state.api_key = action.payload.data.api_key;
            state.enterprise_id = action.payload.data.enterprise_id;
            state.user_id = action.payload.data.user_id;
            state.role = action.payload.data.role;
            state.statusCode = action.payload.status;
        },
        [loginApi.rejected] : (state, action) => {
            state.loading = false;
            state.error = action.payload.data.error;
            state.statusCode = action.payload.status;
        }
    }
});

export const { apiKey , enterpriseId , userRole , userId } = login.actions;
export default login.reducer;