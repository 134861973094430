import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import * as  API from './API_Path';

export const activeChatStatusApi = createAsyncThunk("activeChatStatusApi", async (para,{rejectWithValue}) => {
    const { enterprise_id , user_id , api_key , sortByUpdated , kws } = para;
    const header = {
        'Content-Type': 'application/json',
        'X-I2CE-ENTERPRISE-ID': enterprise_id,
        'X-I2CE-USER-ID' : user_id,
        'X-I2CE-API-KEY' : api_key
    };
    try {
        const response = await axios.get(API.BASE_URL+API.GET_SERVICE_JOBS+"status=active&assigned_agents="+user_id+"&_sort_by=updated&_sort_reverse="+sortByUpdated+API.KEYWORDS_SEARCH+kws,{headers : header});
        const result = response.data;
        console.log(response.data)
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const pendingChatStatusApi = createAsyncThunk("pendingChatStatusApi", async (para,{rejectWithValue}) => {
    const { enterprise_id , user_id , api_key , sortByUpdated , kws , serviceCenterId } = para;
    const header = {
        'Content-Type': 'application/json',
        'X-I2CE-ENTERPRISE-ID': enterprise_id,
        'X-I2CE-USER-ID' : user_id,
        'X-I2CE-API-KEY' : api_key
    };
    try {
        const response = await axios.get(API.BASE_URL+API.GET_SERVICE_JOBS+"status=pending&assigned_agents="+user_id+"&_sort_by=updated&_sort_reverse="+sortByUpdated+API.KEYWORDS_SEARCH+kws,{headers : header});
        const result = response.data;
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const closedChatStatusApi = createAsyncThunk("closedChatStatusApi", async (para,{rejectWithValue}) => {
    const { enterprise_id , user_id , api_key , sortByUpdated , kws , serviceCenterId } = para;
    const header = {
        'Content-Type': 'application/json',
        'X-I2CE-ENTERPRISE-ID': enterprise_id,
        'X-I2CE-USER-ID' : user_id,
        'X-I2CE-API-KEY' : api_key
    };
    try {
        const response = await axios.get(API.BASE_URL+API.GET_SERVICE_JOBS+"status=closed&assigned_agents="+user_id+"&_sort_by=created&_sort_reverse="+sortByUpdated+API.KEYWORDS_SEARCH+kws,{headers : header});
        const result = response.data;
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const ticketCount = createAsyncThunk("ticketCount", async (para,{rejectWithValue}) => {
    const { enterprise_id , user_id , api_key } = para;
    const header = {
        'Content-Type': 'application/json',
        'X-I2CE-ENTERPRISE-ID': enterprise_id,
        'X-I2CE-USER-ID' : user_id,
        'X-I2CE-API-KEY' : api_key
    };
    try {
        const response = await axios.get(API.BASE_URL+API.GET_JOB_STATUS,{headers : header});
        const result = response.data;
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const ticketStatus = createSlice({
    name : 'ticketStatus',
    initialState : {
        activeData : [],
        activeNumber : "",
        activeLoading : null,
        activeError : "",
    },
    extraReducers : {
        [activeChatStatusApi.pending] : (state) => {
            state.activeLoading = true;
        },
        [activeChatStatusApi.fulfilled] : (state, action) => {
            state.activeLoading = false;
            state.activeData = action.payload.data;
            state.activeNumber = action.payload.total_number;
        },
        [activeChatStatusApi.rejected] : (state, action) => {
            state.activeLoading = false;
            state.activeError = action.payload;
        }
    }
});

export const { activeTicketInfo , updateState , updateActiveTicketData , clearActiveTicketData } = ticketStatus.actions;
export default ticketStatus.reducer;