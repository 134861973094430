import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import * as  API from './API_Path';

export const userInfo = createAsyncThunk("userInfo", async (para,{rejectWithValue}) => {
    const { enterprise_id , user_id , api_key } = para;
    const header = {
        'Content-Type': 'application/json',
        'X-I2CE-ENTERPRISE-ID': enterprise_id,
        'X-I2CE-USER-ID' : user_id,
        'X-I2CE-API-KEY' : api_key
    };
    try {
        const response = await axios.get(API.BASE_URL+API.USER_ROLE_API+user_id,{headers : header});
        const result = response.data;
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const userLogged = createSlice({
    name : "userLogged",
    initialState : {
        userInfoData : [],
        userInfoLoading : null,
        error : "",
        userInfoServiceCenterId : "",
        userInfoRole : ""
    },
    extraReducers : {
        [userInfo.pending] : (state) => {
            state.userInfoLoading = true;
        },
        [userInfo.fulfilled] : (state, action) => {
            state.userInfoLoading = false;
            state.userInfoData = action.payload;
            state.userInfoServiceCenterId = action.payload.service_center_id;
            state.userInfoRole = action.payload.agent_role;
        },
        [userInfo.rejected] : (state, action) => {
            state.userInfoLoading = false;
            state.error = action.payload;
        }
    }
});

export default userLogged.reducer;