import React, { useEffect } from 'react';
import './App.css';
import { useDispatch , useSelector } from "react-redux";
import { loginApi } from './feature/LoginSlice';
import { userInfo } from './feature/AgentSlice';
import { activeChatStatusApi } from './feature/ChatStatusSlice';
import { addCustomerAPI } from './feature/AddCustomerOptionSlice';
import * as API from './feature/API_Path';
// import DAVE_SPACE from './feature/daveSocket';

function App() {
  const _DAVE_SPACE = eval("DAVE_SPACE.DAVE_SPACE");
  let centerRoomConnection;
  let chatRoomConnection;
  const dispatch = useDispatch();
  const { loading , statusCode , error , api_key , enterprise_id , user_id } = useSelector( state => state.login_reducer);
  const { addCustomerData } = useSelector( state => state.addCustomer_reducer);
  const { userInfoData , userInfoServiceCenterId , userInfoLoading } = useSelector( state => state.user_reducer);
  function loginCred(uname, pass) {
    let parameters = {
    enterprise_id: "maruti_core",
    password: pass,
    user_id: uname
    }
    dispatch(loginApi(parameters));
  }
  window.loginCred = loginCred;
  function addCustomer( booking_category , booking_date , booking_time , ccm_id , customer_contact_number , customer_name , service_advisor_id , service_center_id , service_type , smre_id , vehicle_model , vehicle_reg_number , wm_id ) {
    const newCustomerData  = {
      api_key : api_key,
      enterprise_id : enterprise_id,
      user_id : user_id,
      customer_response : {
        agent_id : user_id,
        booking_category : booking_category,
        booking_date : booking_date,
        booking_time : booking_time, 
        ccm_id : ccm_id,
        customer_contact_number : customer_contact_number,
        customer_name : customer_name,
        service_advisor_id : service_advisor_id,
        service_center_id : service_center_id,
        service_type : service_type,
        smre_id : smre_id,
        vehicle_model : vehicle_model,
        vehicle_reg_number : vehicle_reg_number,
        wm_id : wm_id
      }
    };
    dispatch(addCustomerAPI(newCustomerData));
  }
  window.addCustomer = addCustomer;
  function chatMessage() {
    const successCallback = (res) => {
      console.log(res);
      chatRoomConnection.registerCallback(dataHandler, "data-callback", "data");
    }
    const errorCallback = (err) => {
        console.log(err);
    }
    const dataHandler = (data) => {
      console.log("P2P - Data Handler - data --> "+JSON.stringify(data));
      console.log(chatRoomConnection);
    }
    if (_DAVE_SPACE) {
      chatRoomConnection  = new _DAVE_SPACE(enterprise_id, addCustomerData.customer_id, api_key, user_id, addCustomerData.space_id, addCustomerData.booking_id+"_"+addCustomerData.space_id, API.SOCKET_URL , successCallback, errorCallback, true, "https://jenkins-yotta.iamdave.ai/event_logger");
      console.log(chatRoomConnection);
    }
    // setTimeout(() => {
    //   chatRoomConnection.response_url ? ((messageContent) => {
    //     console.log("enterprise_id : " + enterprise_id);
    //     console.log("customer_id : " + addCustomerData.customer_id);
    //     console.log("api_key : " + api_key);
    //     console.log("user_id : " + user_id);
    //     console.log("space_id : " + addCustomerData.space_id);
    //     console.log("room_id : " + addCustomerData.booking_id+"_"+addCustomerData.space_id);
    //     const typedMessage = messageContent;
    //     console.log(chatRoomConnection);
    //     if (typedMessage.length > 0) {
    //       let sentMessage  = chatRoomConnection.sendData({"message":typedMessage}, "data-callback");
    //       console.log(sentMessage);
    //     }
    //   })("Hello from " + user_id) : console.log("Chat Room Connection Failed");
    // }, 1000);
  }
  window.chatMessage = chatMessage;
  function sendMessage() {
    let a = chatRoomConnection.response_url ? ((messageContent) => {
      console.log("enterprise_id : " + enterprise_id);
      console.log("customer_id : " + addCustomerData.customer_id);
      console.log("api_key : " + api_key);
      console.log("user_id : " + user_id);
      console.log("space_id : " + addCustomerData.space_id);
      console.log("room_id : " + addCustomerData.booking_id+"_"+addCustomerData.space_id);
      const typedMessage = messageContent;
      console.log(chatRoomConnection);
      if (typedMessage.length > 0) {
        let sentMessage  = chatRoomConnection.sendData({"message":typedMessage}, "data-callback");
        console.log(sentMessage);
      }
    })("Hello from " + user_id) : "Chat Room Connection Failed";
  }
  window.sendMessage = sendMessage;
  useEffect(() => {
    if( loading === false && enterprise_id && api_key && user_id ) {
        const userInfoParam = {
          api_key : api_key,
          enterprise_id : enterprise_id,
          user_id : user_id
        }
        dispatch(userInfo(userInfoParam));
    }
  }, [loading]);
  let centerRoomConnected = false;
  useEffect(() => {
    console.log("Dave Spae---->>",_DAVE_SPACE)
    console.log(enterprise_id);
    console.log(user_id);
    console.log(api_key);
    console.log(userInfoData.room_id);
    console.log(API.SOCKET_URL);
    if(userInfoData.room_id && _DAVE_SPACE) {
        centerRoomConnection  = new _DAVE_SPACE(enterprise_id, user_id+"__load_test__no_message__", api_key, user_id+"__load_test__no_message__", "service_center_space", userInfoData.room_id, API.SOCKET_URL , successCallback, errorCallback, true, "https://jenkins-yotta.iamdave.ai/event_logger");
        centerRoomConnection.response_url ? console.log("Connected") : centerRoomConnected = !centerRoomConnected;
        console.log(centerRoomConnection.response_url);
    }
    console.log(centerRoomConnection);
  }, [userInfoData.room_id,centerRoomConnected,_DAVE_SPACE]);
  // useEffect(() => {
  //   if(addCustomerData) {
  //     chatMessage();
  //   }
  // }, [addCustomerData])
  
  const successCallback = (res) => {
    console.log(centerRoomConnection);
    console.log(res);
    centerRoomConnection.registerCallback(dataHandler, "data-callback", "data");
  }
  const errorCallback = (err) => {
      console.log(err);
  }
  const dataHandler = (data) => {
    console.log(centerRoomConnection);
    console.log("P2P - Data Handler - data --> "+JSON.stringify(data));
    console.log(data);
  }
  return (
    <div className="load-testing-container">
      
      { loading === null ? "Load Testing" : loading === false && statusCode === 200 ? "Login Success" : loading === false && statusCode === 401 ? "Login Failed" : "" }
      { loading === false && enterprise_id && api_key && user_id && userInfoData.room_id ? "User Info Fetched" : ""}
    </div>
  );
}

export default App;
