import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import * as  API from './API_Path';
export const addCustomerAPI = createAsyncThunk("addCustomerAPI", async (para,{rejectWithValue}) => {
    const { enterprise_id , user_id , api_key , customer_response } = para;
    const header = {
        'Content-Type': 'application/json',
        'X-I2CE-ENTERPRISE-ID': enterprise_id,
        'X-I2CE-USER-ID' : user_id,
        'X-I2CE-API-KEY' : api_key
    };
    !customer_response.booking_date ? customer_response.booking_date = new Date().toJSON().slice(0, 10) : console.log();
    !customer_response.booking_time ? customer_response.booking_time = new Date().toLocaleTimeString().slice(0,5) : console.log();
    try {
        const response = await axios.post(API.BASE_URL+"object/service_job",customer_response,{headers : header});
        const result = response.data;
        console.log(result)
        return result;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const addCustomerOption = createSlice({
    name : "addCustomerOption",
    initialState : {
        addCustomerData : [],
        addCustomerLoading : null,
        addCustomerError : ""
    },
    extraReducers : {
        [addCustomerAPI.pending] : (state) => {
            state.addCustomerLoading = true;
        },
        [addCustomerAPI.fulfilled] : (state, action) => {
            state.addCustomerLoading = false;
            state.addCustomerData = action.payload;
        },
        [addCustomerAPI.rejected] : (state, action) => {
            state.addCustomerLoading = false;
            state.addCustomerError = action.payload;
        }
    }
});

// export const { addCustomer , addWalkInCustomer , addAppointmentCustomer , toggleAddCustomerAPIFire } = addCustomerOption.actions;
export default addCustomerOption.reducer;